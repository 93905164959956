export const signin = values => {
    const error = {};

    if (!values.email || !values.email.trim()) {
        error.email = 'Please enter your email address'
    }
    else if (!/^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/i.test(values.email)) {
        error.email = "Please enter valid email address."
    }
    if (!values.password || !values.password.trim()) {
        error.password = 'Please enter password'
    }
    return error;
}

export const createAcc = values => {
    const error = {};

    if (!values.email || !values.email.trim()) {
        error.email = 'Please enter your email address'
    }
    else if (!/^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/i.test(values.email)) {
        error.email = "Please enter a valid email address"
    }
    if (!values.confirmEmail || !values.confirmEmail.trim()) {
        error.confirmEmail = 'Please enter confirm email'
    }
    else if (values.confirmEmail !== values.email) {
        error.confirmEmail = 'Email and confirm email must be same'
    }
    if (!values.password || !values.password.trim()) {
        error.password = 'Please enter password'
    }
    else if (values.password.length < 6 || values.password.length > 16) {
        error.password = 'Password must be within 6 to 16 characters'
    }
    if (!values.confirmPassword || !values.confirmPassword.trim()) {
        error.confirmPassword = 'Please enter confirm password'
    }
    if (values.confirmPassword !== values.password) {
        error.confirmPassword = 'Password and confirm password must be same'
    }
    return error;
}

export const createAccNext = values => {
    const error = {};

    if (!values.email || !values.email.trim()) {
        error.email = 'Please enter email'
    }
    else if (!/^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/i.test(values.email)) {
        error.email = "Please enter valid email."
    }
    if (!values.firstname || !values.firstname.trim()) {
        error.firstname = 'Please enter first name'
    }
    if (!values.lastname || !values.lastname.trim()) {
        error.lastname = 'Please enter last name'
    }
    if (!values.contactNumber || !values.contactNumber.trim()) {
        error.contactNumber = 'Please enter phone number'
    }
    if (!values.country) {
        error.country = 'Select country'
    }
    // if (!values.state && values.country === 'USA') {
    //     error.state = 'Enter state name'
    // }
    // if (!values.photo) { error.photo = 'Select photo' }
    // else if (Object.entries(values.photo).length === 0) {
    //     error.photo = 'Please choose one file'
    // }
    return error;
}

export const editProfile = values => {
    const error = {};

    if (!values.email || !values.email.trim()) {
        error.email = 'Enter email'
    }
    else if (!/^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/i.test(values.email)) {
        error.email = "Enter a valid email."
    }
    if (!values.firstname || !values.firstname.trim()) {
        error.firstname = ' Enter first name'
    }
    if (!values.lastname || !values.lastname.trim()) {
        error.lastname = ' Enter last name'
    }
    if (!values.contactNumber || !values.contactNumber.trim()) {
        error.contactNumber = ' Enter phone number'
    }
    if (!values.country) {
        error.country = ' Select country'
    }
    // if (!values.state && values.country === 'USA') {
    //     error.state = ' Enter state name'
    // }
    // if (!values.photo) { error.photo = 'Select photo' }
    // else if (Object.entries(values.photo).length === 0) {
    //     error.photo = 'Please choose one file'
    // }
    return error;
}

export const changePassword = value => {
    let error = {};

    if (!value.oldPassword) { error.oldPassword = 'Please enter current password' }
    if (!value.password) { error.password = 'Please enter new Password' }
    else if (value.password === value.oldPassword) { error.password = 'Current password and new password can not be same' }
    else if (value.password.length < 6 || value.password.length > 16) { error.password = "Password must be within 6 to 16 characters" }
    if (!value.repeatNewPassword) { error.repeatNewPassword = 'Please re enter new Password' }
    if (value.repeatNewPassword !== value.password) { error.repeatNewPassword = 'New password and repeat new password must be same' }

    return error;
}

export const cardDetails = value => {
    let error = {};

    if (!value.name) { error.name = 'Please enter name' }

    let chckDate = value.date && value.date.trim();
    if (!value.date) { error.date = 'Please enter exp date' }
    else if (chckDate.length < 5) {
        error.date = 'Please enter valid exp date'
    }

    let chckCvv = value.cvv && value.cvv.trim();
    if (!value.cvv) { error.cvv = 'Please enter cvv' }
    else if (chckCvv.length < 3) {
        error.cvv = 'Please enter valid cvv'
    }

    let chckNumber = value.cardNumber && value.cardNumber.trim();
    if (!value.cardNumber) { error.cardNumber = 'Please enter card number' }
    else if (chckNumber.length < 19) {
        error.cardNumber = 'Please enter valid card number'
    }

    const checkAddress = value.address && value.address.trim()
    if (!checkAddress) { error.address = 'Please enter address' }

    const checkCity = value.city && value.city.trim()
    if (!checkCity) { error.city = 'Please enter city' }

    const checkZip = value.zip && value.zip.trim()
    if (!checkZip) { error.zip = 'Please enter zip' }

    const checkCountry = value.country && value.country.trim()
    if (!checkCountry) { error.country = 'Please enter country' }

    return error;
}
