import React, { useEffect, useRef, useState } from 'react';
import { toAbsoluteUrl } from "../../utils";
import { Link, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import OutSideClick from './OutSideClick';
import { logout } from '../../Redux/login/Action';
import { NavLink } from 'react-router-dom';
import queryString from 'query-string';
import SearchList from './SearchList';
import { getAutoComplete, getTermsConditions } from '../../Redux/home/Action';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

const Header = (props) => {

  const parsedQueryValue = queryString.parse(props.location.search);
  const { searchValue } = parsedQueryValue

  const ref = useRef();
  const inputRef = useRef();
  const navButton = useRef();
  const history = useHistory();

  const dispatch = useDispatch();

  const isLoggedIn = useSelector(state => state.login.isLoggedIn);
  const userData = useSelector(state => state.login.profile);
  const cartList = useSelector(state => state.premium.cart.resourceDetails);

  const autoCompleteList = useSelector(state => state.home.autoCompleteList);

  const [Menu, setMenu] = useState(false);
  const [Search, setSearch] = useState(false);
  const [Mobile, setMobile] = useState(false);
  const [Hamburger, setHamburger] = useState(false);
  const [Logout, setLogout] = useState(false);
  const [SearchText, setSearchText] = useState('');
  const [isPremiumShow, setIsPremiumShow] = useState(false);


  // OutSideClick(ref, () => {
  //   if (Mobile) { setMobile(false); }
  // });
  useEffect(() => {
    const abortController = new AbortController();

    dispatch(getTermsConditions())
      .then(res => {
        setIsPremiumShow(res?.data?.isPremiumEnabled)
      })
      .catch(err => console.log("err", err))

    return () => {
      abortController.abort();
    }
  }, [dispatch])

  useEffect(() => {
    setSearchText(searchValue ? searchValue : '');
    if (!searchValue) {
      inputRef.current.value = ''
    }
    else {
      inputRef.current.value = searchValue
    }
  }, [searchValue])

  OutSideClick(inputRef, () => {
    if (Search) { setSearch(false); }
  });

  OutSideClick(inputRef, () => {
    if (Mobile) { setMobile(false); }
  });

  OutSideClick(ref, () => {
    if (Menu) { setMenu(false); }
  });

  OutSideClick(ref, () => {
    if (Logout) { setLogout(false); }
  });

  OutSideClick(navButton, () => {
    // navbarLinks.current.classList.add('menu-collapse');
    setHamburger(false)
  });


  const mobileOnclick = e => {
    e.preventDefault();

    setHamburger(false);
    setMobile(!Mobile);

    if (!inputRef.current.value || !inputRef.current.value.trim())
      setSearch(false)
    else
      setSearch(true)

    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.click();
    }, 200);

    if (Menu || Search || Logout) {
      setMenu(false);
      setSearch(false);
      setLogout(false);
    }
  };
  const handleOnclick = e => { e.preventDefault(); setHamburger(false); setMenu(!Menu); if (Mobile || Search || Logout) { setMobile(false); setSearch(false); setLogout(false); } };
  // const searchOnclick = e => { e.preventDefault(); setSearch(!Search) };
  const logoutOnclick = e => { e.preventDefault(); setHamburger(false); setLogout(!Logout); if (Mobile || Search || Menu) { setMobile(false); setSearch(false); setMenu(false); } };

  const onConfirmationPopUp = (e, message, path) => {
    e.preventDefault();
    swal({
      title: message,
      icon: "",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          history.push(`/signin/${path}`)
        }
      });
  }

  const handleLogout = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure you want to logout?",
      icon: "",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(logout({ deviceId: userData.deviceId }))
            .then(res => {
              if (res.status === 200) {
                // swal("Logout successfully", { icon: "", });
                history.push('/');
                window.location.reload();
              }
              else
                window.location.reload();
            })
        }
      });
  }

  const onSearchChange = (e) => {
    if (!e.target.value || !e.target.value.trim())
      setSearch(false)
    else
      setSearch(true)
    setSearchText(e.target.value)
    dispatch(getAutoComplete({ searchValue: e.target.value, searchType: 'all' }))
  }


  const onSearchClick = (e) => {
    setSearchText(e.target.value)

    if (!e.target.value || !e.target.value.trim())
      setSearch(false)
    else
      setSearch(true)

    dispatch(getAutoComplete({ searchValue: e.target.value, searchType: 'all' }))
    if (!(!SearchText || !SearchText.trim())) {
      history.push(`/search-result?searchValue=${e.target.value}`)
      setMobile(false)
      setSearch(false)

      setTimeout(() => {
        inputRef.current.blur();
      }, 200);
    }
  }
  const onInputCloseIcon = () => {
    inputRef.current.value = '';
    setSearchText('')

    if (history.location.pathname.startsWith('/search-result'))
      history.push(`/search-result`)
  }

  // const onFocus = (e) => {
  //   // setSearch(true)
  //   // inputRef.current.value = searchValue ? searchValue : ''
  //   // dispatch(getAutoComplete({ searchValue: e.target.value, searchType: 'all' }))
  // }

  const handleNavbarButton = (e) => {
    setHamburger(!Hamburger)
  };

  return (
    <>
      <header className="app-header d-flex align-items-center justify-content-between flex-wrap">
        <div className="head-left">
          <div className="logo-box">
            <Link to="/" className="dialog-close">
              <img src={toAbsoluteUrl("/njaal_logo_white.svg")} width="130.564" height="31.976" alt="Logo Img" />
            </Link>
          </div>
          <div className="header-option">
            <nav className='navbar'>
              <button ref={navButton} onClick={(e) => { handleNavbarButton(e); }} className='navbar-toggler'>
                <span className='navbar-toggler-icon'></span>
              </button>
              <div className={`navbar-links ${Hamburger ? '' : 'menu-collapse'} `}>
                <NavLink activeClassName='is-active' onClick={() => { setHamburger(false) }} className='nav-link' to='/'>Resources</NavLink>
                {isPremiumShow && <NavLink activeClassName='is-active' onClick={() => { setHamburger(false) }} className='nav-link' to='/premium'>Premium</NavLink>}
              </div>
            </nav>
          </div>
        </div>
        <div className="head-right">
          <div className="return-main-page">
            <a href="https://www.notjustanaveragejoe.com/" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="12px" viewBox="532.1 533.9 16 12">
                <path className="st0" d="M545.1,537.2h-9.6l1.3-1.4c0.4-0.4,0.4-1.1,0-1.5c-0.4-0.4-1-0.4-1.4,0l-3,3.3c-0.1,0.1-0.2,0.2-0.2,0.4 c-0.1,0.3-0.1,0.6,0,0.8c0,0.1,0.1,0.3,0.2,0.4l3,3.3c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0c0.4-0.4,0.4-1.1,0-1.5c0,0,0,0,0,0l-1.3-1.4 h9.6c0.6,0,1,0.5,1,1.1v4.4c0,0.6,0.4,1.1,1,1.1c0.6,0,1-0.5,1-1.1v-4.4C548.1,538.6,546.8,537.2,545.1,537.2z" />
              </svg>
              <span>Return to Main  Site</span>
            </a>
          </div>
          <div className="header-search-box">
            <Button className="btn search-mobile-ui" type="submit" onClick={mobileOnclick}><img src={toAbsoluteUrl("/images/Search_White.svg")} alt="" /></Button>
            <div className={`header-search ${Mobile ? 'active' : ''}`} >
              <input type="text"
                ref={inputRef}
                className="effect-bg"
                placeholder="Search by resource, tags and topics"
                defaultValue={SearchText ? SearchText : ''}
                onChange={onSearchChange}
                // onFocus={onFocus}
                // autoFocus={true}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    onSearchClick(e);
                    if (SearchText === '' && history.location.pathname.startsWith('/search-result')) {
                      history.push(`/search-result`)
                    }
                  }
                }}
                required />
              <Button className="btn" type="submit" style={{ backgroundImage: `url(${toAbsoluteUrl('/images/Search_White.svg')})` }}><img src={toAbsoluteUrl("/images/Search_Gray.svg")} alt="" /></Button>
              <span className="focus-bg"></span>
              <span className="focus-close" onClick={(e) => { e.preventDefault(); onInputCloseIcon(); }}><img src={toAbsoluteUrl("/images/close-btn-img-dark.svg")} alt="" /></span>
              <div className={`header-search-popup ${Search ? 'active' : ''}`}>
                <PerfectScrollbar>
                  <ul>
                    {autoCompleteList.length > 0 ? autoCompleteList.map((val, i) => {
                      return <SearchList key={i}
                        name={val.name}
                        setMobile={setMobile}
                        id={val._id}
                        inputRef={inputRef}
                        searchValue={searchValue}
                      />
                    }) : (SearchText && <SearchList noData={true} inputRef={inputRef} name="No Result Found" />)}
                  </ul>
                </PerfectScrollbar>
              </div>
            </div>
          </div>
          <div className="header-cart">
            {isLoggedIn ? <Link to={`/cart`}>
              <img src={toAbsoluteUrl("/images/np_cart.svg")} alt="" />
              <span className="cart-item-number">{cartList.length > 0 ? cartList.length : ''}</span>
            </Link> :
              <Link to={'/'} onClick={(e) => onConfirmationPopUp(e, "Please login to see your cart.", 'cart')}>
                <img src={toAbsoluteUrl("/images/np_cart.svg")} alt="" />
              </Link>}
          </div>
          <div className="header-status">
            {!isLoggedIn ? <>
              <div className="logout-user-status" ref={ref} onClick={logoutOnclick} style={{ backgroundImage: `url(${toAbsoluteUrl('/images/user-white.svg')})` }}>
                <div className={`logout-desktop ${Logout ? 'active' : ''}`}>
                  <Link to="/signin" className="">Log in</Link>
                  <span>/</span>
                  <Link to="/create-account" className="">Sign up</Link>
                </div>
              </div>
            </> :
              // profile Dropdown
              <>
                <div onClick={handleOnclick} ref={ref} className="login-user-status">
                  <div className="login-user-img"><img src={userData.photo ? userData.photo : toAbsoluteUrl("/images/plus.svg")} alt="" /></div>
                  <div className="login-user-name" style={{ textTransform: 'capitalize' }}>{`${userData.firstname ? userData.firstname : ''} ${userData.lastname ? userData.lastname : ''} `}</div>
                </div>
                <div className={`header-user-popup ${Menu ? 'active' : ''}`}>
                  <ul>
                    <li><Link to="/profile-edit" className="user-popup-text"><div className="user-popup-icon"><img src={toAbsoluteUrl("/images/Profile.svg")} alt="" /></div>Profile</Link></li>
                    <li><Link to="/change-password" className="user-popup-text"><div className="user-popup-icon"><img src={toAbsoluteUrl("/images/Change_Password.svg")} alt="" /></div>Change Password</Link></li>
                    <li><Link to="/payment-method" className="user-popup-text"><div className="user-popup-icon"><img src={toAbsoluteUrl("/images/Payment.svg")} alt="" /></div>Payment Method</Link></li>
                  </ul>
                  <Link to="/" onClick={handleLogout} className="">Logout</Link>
                </div>
              </>
            }
          </div>
        </div>
      </header>
    </>
  )
}

// function AllRoutes({ hideMenu }) {

//   return (
//     <Switch>
//       <Route path="/about"></Route>
//       <Route path="/contact"></Route>
//       <Route path="/"></Route>
//     </Switch>
//   );
// }
export default Header;