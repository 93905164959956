import * as ACTION_TYPES from './Types';

const initialState = {
  resourses: { data: [] },
  tagNames: [],
  autoCompleteList: [],
  searchList: { recordsTotal: 0, data: [] },
  ViewMoreData:[],
  cmsData: {},
  message: '',
  error: null
}

export const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_RESOURSES_LIST_SUCCESS:
      return {
        ...state,
        resourses: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_RESOURSES_LIST_ERROR:
      return {
        ...state,
        resourses: { data: [] },
      }
    case ACTION_TYPES.GET_RESOURSE_INFO_SUCCESS:
      return {
        ...state,
        resourseInfo: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_TAG_NAME_SUCCESS:
      return {
        ...state,
        tagNames: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_AUTO_COMPLETE_SUCCESS:
      return {
        ...state,
        autoCompleteList: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_AUTO_COMPLETE_ERROR:
      return {
        ...state,
        autoCompleteList: [],
        // message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        searchList: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_SEARCH_LIST_ERROR:
      return {
        ...state,
        searchList: { recordsTotal: 0, data: [] }
      }
      case ACTION_TYPES.GET_VIEW_MORE_LIST_SUCCESS:
        return {
          ...state,
          ViewMoreData: action.payload.data,
          message: action.payload.message,
          error: null
        }
      case ACTION_TYPES.GET_VIEW_MORE_LIST_ERROR:
        return {
          ...state,
          ViewMoreData:[]       
         }
  
    case ACTION_TYPES.GET_TERMS_CONDITION_SUCCESS:
      return {
        ...state,
        cmsData: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_TERMS_CONDITION_ERROR:
      return {
        ...state,
        cmsData: {}
      }
    default:
      return state;
  }
}
